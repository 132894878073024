import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import { SidebarNavItem } from '@/helpers/mapNavigationToSidebarItems';
import classnames from 'classnames';

export interface SidebarNavigationItemProps extends SidebarNavItem {
  onClick?: (id: string) => void;
}

export function SidebarNavigationItem(props: SidebarNavigationItemProps) {
  function handleOnClick() {
    props.onClick && props.onClick(props.id);
  }

  return (
    <li
      className={classnames('SidebarNavigationItem', {
        'SidebarNavigationItem--current': props.current,
      })}
    >
      {props.link ? (
        <LinkWrapper className="SidebarNavigationItem__text" href={props.link}>
          <span>{props.shortTitle || props.title}</span>
        </LinkWrapper>
      ) : (
        <span className="SidebarNavigationItem__text" onClick={handleOnClick}>
          <span>{props.shortTitle || props.title}</span>
          <Icon variant={IconVariant.ChevronRight} />
        </span>
      )}
    </li>
  );
}
